import {
	cloneDeep,
} from 'lodash';
import axios from 'axios';
import {
	useEffect,
	useState,
	useCallback,
	useMemo,
} from 'react';
import {
	Redirect,
	Link,
} from 'react-router-dom';
import {
	CardNumberElement,
	CardCvcElement,
	CardExpiryElement,
	useStripe,
	useElements,
} from "@stripe/react-stripe-js";
import {
	Form,
	Button,
	Input,
	Card,
	Cascader,
	Spin,
	message,
	notification,
} from 'antd';
import {
	UserOutlined,
	MailOutlined,
} from '@ant-design/icons';
import {
	makeStyles,
} from '@material-ui/core';
import {
	createUniqueGroupId,
} from '../../utilities';

const useStyles = makeStyles({
	form: {
		maxWidth: 700,
		margin: '0 auto',
		paddingTop: 30,
	},
	playerCard: {
		marginBottom: 20,
	},
	full: {
		textAlign: 'center',
	},
	fullIcon: {
		maxWidth: '90%',
	},
	donationButton: {
		marginTop: '25px',
	}
});

const INITIAL_PLAYERS = [
	{
		name: '',
		email: '',
		shirtSize: '',
	},
	{
		name: '',
		email: '',
		shirtSize: '',
	},
	{
		name: '',
		email: '',
		shirtSize: '',
	},
	{
		name: '',
		email: '',
		shirtSize: '',
	},
];

const SHIRT_SIZE_OPTIONS = [
	{
		value: 'XS',
		label: 'XS',
	},
	{
		value: 'S',
		label: 'S',
	},
	{
		value: 'M',
		label: 'M',
	},
	{
		value: 'L',
		label: 'L',
	},
	{
		value: 'XL',
		label: 'XL',
	},
	{
		value: 'XXL',
		label: 'XXL',
	},
	{
		value: 'XXXL',
		label: 'XXXL',
	}
]

function showRegisterPlayersError() {
	notification.error({
		duration: null,
		message: 'Error Occurred',
		description: 'Your payment was processed, but something went wrong when registering players. DO NOT RESUBMIT. Please contact us at info@forenora.com'
	});
}

function showSuccessMessage() {
	notification.success({
		duration: null,
		message: 'Success!',
		description: 'Payment processed and players registered successfully! Check your email for a receipt of your payment.'
	});
}

export function Register() {
	const styles = useStyles();

	// State Hooks
	const [totalPlayers, setTotalPlayers] = useState(null);
	const [shouldRedirect, setShouldRedirect] = useState(false);
	const [disableSubmit, setDisableSubmit] = useState(true);
	const [stripeClientSecret, setStripeClientSecret] = useState('');
	const [receiptEmail, setReceiptEmail] = useState('');
	const [cardError, setCardError] = useState(null);
	const [isProcessing, setIsProcessing] = useState(false);
	const [numberOfPlayers, setNumberOfPlayers] = useState(4);
	const [failedValidation, setFailedValidation] = useState(false);
	const [allPlayerInfo, setAllPlayerInfo] = useState(cloneDeep(INITIAL_PLAYERS));

	// Stripe Hooks
	const stripe = useStripe();
	const elements = useElements();

	// Memos
	const registrationIsOpen = false;

	// Effects
	useEffect(() => {
		setDisableSubmit(true);
		axios.post('/api/payment/createPaymentIntent', {numOfPlayers: numberOfPlayers})
			.then(({status, data}) => {
				if (status === 200) {
					setStripeClientSecret(data.clientSecret);
					setDisableSubmit(false);
				} else {
					message.error('Something went wrong getting the data for this page. Please reload and try again.', 10)
				}
			});
	}, [numberOfPlayers]);
	useEffect(() => {
		axios.get('/api/players/total').then((response) => setTotalPlayers(response.data));
	}, []);

	// Callback Hooks
	const handlePlayerInfoChange = useCallback(({target: {value, name}}, index) => {
		const playerInfoCopy = cloneDeep(allPlayerInfo);
		playerInfoCopy[index][name] = value;
		setAllPlayerInfo(playerInfoCopy);
	}, [allPlayerInfo]);
	const handleCardChange = useCallback(async (event) => {
		setDisableSubmit(event.empty);
		setCardError(event.error ? event.error.message : '');
	}, []);
	const validateForm = useCallback(() => {
		if (!receiptEmail) {
			return false;
		}

		let valid = true;

		allPlayerInfo.forEach(({name, email, shirtSize}, index) => {
			if (index >= numberOfPlayers) {
				return;
			}
			if (!name || !email || !shirtSize) {
				valid = false;
			}
		});

		return valid;
	}, [allPlayerInfo, numberOfPlayers, receiptEmail]);

	const registerPlayers = useCallback((paymentId) => {
		// Format Player Data for Save
		const groupId = createUniqueGroupId();
		const allPlayerInfoCopy = cloneDeep(allPlayerInfo);
		const playersToSave = allPlayerInfoCopy.slice(0, numberOfPlayers);
		const formattedPlayersToSave = playersToSave.map((player) => {
			player.shirtSize = player.shirtSize[0];
			player.groupId = groupId;
			player.paymentId = paymentId;
			return player;
		});

		// Save Player Data
		axios.post('/api/players', {players: formattedPlayersToSave})
			.then(({data}) => {
				if (data.error) {
					showRegisterPlayersError();
					setDisableSubmit(true);
					setIsProcessing(false);
				} else {
					showSuccessMessage();
					setShouldRedirect(true);
				}
			})
			.catch((error) => {
				showRegisterPlayersError();
				setDisableSubmit(true);
				setIsProcessing(false);
				console.log(error);
			});
	}, [allPlayerInfo, numberOfPlayers]);

	const handleSubmitPaymentAndRegister = useCallback(() => {
		// If we don't have the correct stripeClientSecret
		if (disableSubmit || !stripeClientSecret) {
			message.error('We are unable to submit your request. Please reload and try again.', 10)
			return;
		}

		// Validate Players to Register
		if (!validateForm()) {
			message.error('Oops! Please fill out any missing information and try again.', 10)
			setFailedValidation(true);
			return;
		}

		// Begin Processing Payment
		setIsProcessing(true);

		stripe.confirmCardPayment(stripeClientSecret, {
			receipt_email: receiptEmail,
			payment_method: {
				card: elements.getElement(CardNumberElement)
			},
		})
			.then((response) => {
				if (response.error) {
					message.error(`Stripe Payment Error: ${ response.error.message }`, 10);
					setIsProcessing(false)
				} else {
					registerPlayers(response.paymentIntent.id);
				}
			})
			.catch(() => {
				message.error('Something went wrong processing your payment. Please try again.', 10);
				setIsProcessing(false);
			});

	}, [registerPlayers, validateForm, stripeClientSecret, disableSubmit, elements, stripe, receiptEmail]);

	// Possible Renders

	if (shouldRedirect) {
		return <Redirect to="/home" />;
	}

	if (totalPlayers === null) {
		return <Spin size="large" />;
	}

	if (!registrationIsOpen) {
		return (
			<>
				<h1>Player Registration</h1>
				<hr />
				<div className={ styles.full }>
					<img className={ styles.fullIcon } alt="golfer" src="https://img.icons8.com/ios/452/golf-cart.png" />

					<h2>Player registration will open on <strong>May 5, 2025</strong>!</h2>
					<h3>Please come back at that time to register your foursome.</h3>

					<div className={ styles.donationButton }>
						<Button type="primary" size="large">
							<Link to="/donate">Make a Donation</Link>
						</Button>
					</div>
				</div>
			</>
		);
	}

	if (totalPlayers && totalPlayers >= 144) {
		return (
			<>
				<h1>Player Registration</h1>
				<hr />
				<div className={ styles.full }>
					<img className={ styles.fullIcon } alt="golfer" src="https://img.icons8.com/ios/452/golf-cart.png" />

					<h2>Player registration is full! If you would still like to make a donation, please click the button below.</h2>

					<Button type="primary" size="large">
						<Link to="/donate">Make a Donation</Link>
					</Button>
				</div>
			</>
		);
	}

	return (
		<>
			<h1>
				Player Registration
				&nbsp;
				{ (Boolean(totalPlayers) && totalPlayers >=70) && (
					`— Only ${ (144 - totalPlayers) } spots left!`
				) }
			</h1>
			<p>
				The tournament will be a 4-person scramble. Register your entire team here!
			</p>
			<p>
				<strong>Player registration will be capped at 144 total players (36 groups)</strong>
			</p>

			

			<p>Please send any questions about registration to <a href="mailto:info@forenora.com">info@forenora.com</a></p>
			<h3>Cost: $100 Per Player</h3>
			<hr />

			<Form className={ styles.form } labelCol={{span: 5}}>
				{ allPlayerInfo.map(({name, email, shirtSize}, index) => {
					if (index >= numberOfPlayers) {
						return null;
					}
					return (
						<Card className={ styles.playerCard } key={ index } size="small" title={ `Player ${ index + 1 }` }>
							<Form.Item label="First & Last Name" validateStatus={ failedValidation && !name ? 'error' : null }>
								<Input
									onChange={ (event) => handlePlayerInfoChange(event, index) }
									name="name" prefix={ <UserOutlined /> }
									value={ name }
								/>
							</Form.Item>
							<Form.Item label="Email" validateStatus={ failedValidation && !email ? 'error' : null }>
								<Input
									onChange={ (event) => handlePlayerInfoChange(event, index) }
									name="email" prefix={ <MailOutlined /> }
									value={ email }
								/>
							</Form.Item>
							<Form.Item label="T-shirt Size" validateStatus={ failedValidation && !shirtSize.length ? 'error' : null }>
								<Cascader
									onChange={ (value) => handlePlayerInfoChange({target: {value, name: 'shirtSize'}}, index) }
									options={ SHIRT_SIZE_OPTIONS }
									placeholder="Please Select"
									value={ shirtSize }
								/>
							</Form.Item>
						</Card>
					)
				}) }

				<h3>Total Cost: ${ numberOfPlayers * 100 }</h3>

				<hr />

				<h4>Payment Information (Processed via Stripe)</h4>
				<Form.Item validateStatus={ failedValidation && !receiptEmail ? 'error' : null }>
					<Input
						onChange={ (event) => setReceiptEmail(event.target.value || '') }
						placeholder="Email to send payment receipt"
						prefix={ <MailOutlined /> }
						type="text"
						value={ receiptEmail }
					/>
				</Form.Item>

				<h4>Card Information</h4>
				<Form.Item>
					<CardNumberElement
						className="ant-input"
						onChange={ handleCardChange }
						options={ {
							style: {
								base: {
									fontSize: '16px',
								}
							}
						}}
					/>
					<CardExpiryElement
						className="ant-input"
						onChange={ handleCardChange }
						options={ {
							style: {
								base: {
									fontSize: '16px',
								}
							}
						}}
					/>
					<CardCvcElement
						className="ant-input"
						onChange={ handleCardChange }
						options={ {
							style: {
								base: {
									fontSize: '16px',
								}
							}
						}}
					/>
				</Form.Item>

				<Button
					disabled={ disableSubmit }
					loading={ isProcessing }
					onClick={ handleSubmitPaymentAndRegister }
					type="primary"
				>
					Submit Payment & Register
				</Button>
				{ cardError && <p>{ cardError }</p>}

			</Form>
		</>
	)
}
