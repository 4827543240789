import {
	makeStyles,
} from '@material-ui/core';
import {
	Link,
} from 'react-router-dom';
import {
	Button,
} from 'antd';

const useStyles = makeStyles((theme) => ({
	header: {
		textAlign: 'center',
	},
	flexContainer: {
		paddingTop: 30,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
		[theme.breakpoints.only('xs')]: {
			flexDirection: 'column',
		},
	},
	map: {
		border: 0,
		height: 400,
		width: 400,
		maxWidth: '100%'
	},
	details: {
		paddingBottom: 30,
	},
}));

export function EventDetails() {
	const styles = useStyles();

	return (
		<>
			<h1 className={ styles.header }>Fore Nora: A Tournament with Heart</h1>
			<div className={ styles.flexContainer }>
				<div className={ styles.details }>
					<h3>4-person scramble</h3>
					<h3>September 12, 2025</h3>
					<h3>Location: Fox Hollow Golf Course in American Fork, Utah</h3>
					<h3>Arrive & Check-in: 7:15 AM to 7:45 AM</h3>
					<h3>Tee Off: 8:00 AM</h3>
					<h3>Luncheon with awards and raffle following play!</h3>
					<Link to="/register">
						<Button type="outlined">Register Here!</Button>
					</Link>
				</div>
				<div>
					<iframe
						className={ styles.map }
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3038.2495895313464!2d-111.79018858460448!3d40.403321279366665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x874d811e45939b71%3A0xc106f16d5ec85acf!2sFox%20Hollow%20Golf%20Course!5e0!3m2!1sen!2sus!4v1624763493432!5m2!1sen!2sus"
						title="Fox Hollow Golf Course"
						allowFullScreen=""
						loading="lazy"
					/>
				</div>
			</div>
		</>

	);
}
