import {
	makeStyles,
} from '@material-ui/core';
import {
	Button,
	Card,
} from 'antd';
import {
	Link,
} from 'react-router-dom';
import { ReactComponent as ForeNoraLogo } from '../../icons/fore-nora-logo.svg';

const useStyles = makeStyles({
	container: {

	},
	logoContainer: {
		width: 400,
		maxWidth: '90%',
		margin: '0 auto 40px',
		paddingBottom: 40,
		borderBottom: '1px solid black',
	},
	textAlignCenter: {
		textAlign: 'center',
	},
	card: {
		maxWidth: 800,
		margin: '0 auto 30px',
	},
	pictureContainer: {
		textAlign: 'center',
		marginBottom: 20,
	},
	picture: {
		maxWidth: 200,
	},
	subTitle: {
		textAlign: 'center',
		maxWidth: 800,
		margin: '0 auto',
	},
	mainCta: {
		textAlign: 'center',
		padding: '50px 0'
	},
	fluxLogo: {
		width: 200,
	}
});

export function Home() {
	const styles = useStyles();
	const cardActions = [
		<Link to="/register">
			<Button type="primary">
				Register Here
			</Button>
		</Link>,
		<Link to="/donate">
			<Button>
				Donate Here
			</Button>
		</Link>];

	return (
		<div className={ styles.container }>
			<div className={ styles.logoContainer }>
				<ForeNoraLogo />
			</div>

			{/* <div className={ styles.textAlignCenter }>
					<p>
						Sponsored by
						&nbsp;
						<a href="https://www.fluxrealestate.com" target="_blank" rel="nooponer noreferrer">
							<img className={ styles.fluxLogo } alt="flux" src="https://i.imgur.com/eVdOnK5.png" />
						</a>
					</p>
			</div> */}

			<h1 className={ styles.subTitle }>Benefitting Children with Congenital Heart Disease and Their Families</h1>
			<h2 className={ styles.subTitle }>
				At&nbsp;
				<a href="https://intermountainhealthcare.org/primary-childrens" target="_blank" rel="noreferrer">Primary Children's Hospital</a>
				&nbsp;through the&nbsp;
				<a href="https://intermountainhealthcare.org/giving-volunteering/intermountain-foundation/about-us/" target="_blank" rel="noreferrer">Intermountain Foundation</a>.
			</h2>

			<div className={ styles.mainCta }>
				<Button type="primary" size="large">
					<Link to="/register">
						Register Today!
					</Link>
				</Button>
			</div>

			<Card actions={ cardActions } className={ styles.card }>
				<h1 className={ styles.textAlignCenter }>Hosted by <em>The Nora Mae Budge Foundation</em></h1>
				<h2 className={ styles.textAlignCenter }>Friday, September 12, 2025 - 8:00 AM Tee Off</h2>
				<h2 className={ styles.textAlignCenter }>4-person Scramble - 18 Holes</h2>
				<h2 className={ styles.textAlignCenter }>Fox Hollow Golf Course - American Fork, Utah</h2>
			</Card>

			<Card className={ styles.card }>
				<div className={ styles.pictureContainer }>
					<img className={ styles.picture } alt="nora" src="https://i.imgur.com/eDXTgJA.jpg" />
				</div>
				<p>
					Inspired by the short, but impactful life of <Link to="/nora">Nora Mae Budge</Link>,
					<strong> Fore Nora: A Tournament with Heart </strong>
					is a charitable golf tournament focused on helping infants and children who suffer from
					<a href="https://www.chop.edu/conditions-diseases/congenital-heart-disease"> congenital heart disease</a>,
					their families, and their caregivers.
				</p>
				<p>
					Commonly known as <em>Heart Warriors</em>, these children face challenges that few will ever experience themselves, or even know about, often requiring multiple open-heart surgeries, and months-long stays at the hospital.
					The care that medical professionals provide for these children is complex; the emotional, mental, and financial toll on their families is enormous; and the goal of this great struggle is to simply <em>give these children a chance to live a life, much like the one we may take for granted.</em>
				</p>
				<p>
					<strong>
						All net proceeds will be donated to help children with CHD and their families at&nbsp;
						<a href="https://intermountainhealthcare.org/primary-childrens" target="_blank" rel="noreferrer">Primary Children's Hospital</a>
						&nbsp;through the&nbsp;
						<a href="https://intermountainhealthcare.org/giving-volunteering/intermountain-foundation/about-us/" target="_blank" rel="noreferrer">Intermountain Foundation</a>.
					</strong>
				</p>
			</Card>
		</div>
	)
}
